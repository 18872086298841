<template>
  <div>
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-modal
        hide-footer
        id="modal-email"
        ref="my-modal"
        :title="`Enviar Email`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Correo"
          >
            <b-form-input
                v-model="send.email"
                placeholder="Correo"
                type="email"
            />
            <span v-if="email_is" style="color: red">Correo requerido </span>
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-button
              @click="sendEmail"
              style="width: 100%"
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
          >
            Enviar
          </b-button>

        </b-col>

      </b-row>
    </b-modal>
    <b-modal
        hide-footer
        id="modal-phone"
        ref="my-modal"
        :title="`Enviar whatsapp`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Whatsapp"
          >
            <b-form-input
                v-model="send.phone"
                placeholder="Whatsapp"
                type="email"
            />
            <span v-if="phone_is" style="color: red">Celular requerido </span>
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-button
              @click="sendWhatsapp"
              style="width: 100%"
              class="mt-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
          >
            Enviar
          </b-button>

        </b-col>

      </b-row>
    </b-modal>
    <b-modal
        hide-footer
        id="modal-copy"
        ref="my-modal"
        :title="`Copiar Links`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        size="xl"
    >
      <b-row v-for="(a, idx) in lines_copy" :key="idx">
        <b-col cols="6">
          <p>{{ a.title }}</p>
          <b-form-input v-model="a.url" disabled="">
          </b-form-input>
        </b-col>
        <b-col cols="6">
          <br><br>
          <b-button
              @click="copyLink(a.url)"
              style="width: 25%"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
              class="mr-1"
          >
            Copiar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-row>
      <b-col cols="12">
        <h3 style="text-align: center">Compartir</h3>
      </b-col>
      <b-col cols="12">
        <b-row class="mt-5">
          <b-col cols="4">
            <h3 style="text-align: center">Recetas</h3>
            <b-list-group>
              <b-list-group-item style="cursor:pointer;" v-for="(a, idx) in recipes" :key="idx"
              >
                <b-form-checkbox
                    v-model="a.status"
                    @input="checkEmail(idx, a.id, 'recipe', a.status)"
                >
                </b-form-checkbox>
                {{ resHoursOdooFormat(a.createdAt) }}(ver
                archivo)
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="4">
            <h3 style="text-align: center">Esquemas</h3>
            <b-list-group>
              <b-list-group-item v-for="(b, idx2) in list_schemes" :key="idx2" style="cursor:pointer;"

              >
                <b-form-checkbox
                    v-model="b.status"
                    @input="checkEmail(idx2, b.id, 'schemes', b.status)"
                >
                </b-form-checkbox>
                {{ b.name }} (ver
                archivo {{ resHoursOdooFormat(b.createdAt) }})
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="4">
            <h3 style="text-align: center">Tratamientos</h3>
            <b-list-group>
              <b-list-group-item v-for="(c, idx3) in treatments" :key="idx3" style="cursor:pointer;"

              >
                <b-form-checkbox
                    v-model="c.status"
                    @input="checkEmail(idx3, c.id, 'treatment', c.status)"
                >
                </b-form-checkbox>
                {{ c.name }} (ver
                archivo {{ resHoursOdooFormat(c.createdAt) }})
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="12">
          </b-col>
          <b-col cols="4">
            <h3 style="text-align: center">Estudios de laboratorio</h3>
            <b-list-group>
              <b-list-group-item v-for="(d, idx4) in studiesLab" :key="idx4" style="cursor:pointer;"

              >
                <b-form-checkbox
                    v-model="d.status"
                    @input="checkEmail(idx4, d.id, 'studies', d.status)"
                >
                </b-form-checkbox>
                (ver
                archivo {{ resHoursOdooFormat(d.createdAt) }})
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="4">
            <h3 style="text-align: center">Interconsultas</h3>
            <b-list-group>
              <b-list-group-item v-for="(z, idx5) in interConsults" :key="idx5" style="cursor:pointer;"

              >
                <b-form-checkbox
                    v-model="z.status"
                    @input="checkEmail(idx5, z.id, 'inter', z.status)"
                >
                </b-form-checkbox>
                (ver
                archivo {{ resHoursOdooFormat(z.createdAt) }})
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="12">
          </b-col>
          <b-col cols="4" class="mt-4">
            <b-button v-if="send.array_send.length > 0"
                      @click="openModal"
                      style="width: 100%"
                      class="mt-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
            >
              Enviar Por correo
            </b-button>
          </b-col>
          <b-col cols="4" class="mt-4">
            <b-button v-if="send.array_send.length > 0"
                      @click="openModalPhone"
                      style="width: 100%"
                      class="mt-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
            >
              Enviar Por Whatsapp
            </b-button>
          </b-col>
          <b-col cols="4" class="mt-4">
            <b-button v-if="send.array_send.length > 0"
                      @click="openModalCopy"
                      style="width: 100%"
                      class="mt-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
            >
              Enviar Urls
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm, BFormFile,
  BFormGroup,
  BFormInput, BFormRadio, BFormSelect,
  BFormTextarea,
  BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BListGroup, BListGroupItem, BModal, BOverlay,
  BRow, VBModal, BFormCheckbox,
} from "bootstrap-vue";
import slugify from 'slugify'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical";
import flatPickr from "vue-flatpickr-component";
import {mapActions, mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import Ripple from "vue-ripple-directive";
import moment from 'moment';
import logo from "@/assets/images/logo/logo-1.png";
import {dateFolio, dateStringMonth, dateTodayFormat, formatHour, sumMonthsHours} from "@/utils/date";

export default {
  name: "PatientShare",
  components: {
    BForm,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StatisticCardVertical,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BAvatar,
    BFormFile,
    flatPickr,
    BFormSelect,
    BFormRadio,
    ToastificationContent,
    AppCollapse,
    BModal,
    BOverlay,
    BListGroup,
    BListGroupItem,
    VBModal,
    AppCollapseItem,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    title: String,
    data_patient: Object
  },
  data() {
    return {
      patient_id: null,
      show: false,
      email_is: false,
      note_evolution: null,
      phone_is: false,
      number_picking: null,
      recipes: [],
      list_schemes: [],
      studiesLab: [],
      treatments: [],
      interConsults: [],
      check_recipe: [],
      lines_copy: [],
      pricelist_patient: null,
      send: {
        bucket: null,
        name: null,
        id: null,
        email: null,
        phone: null,
        array_send: []
      }
    }
  },
  mounted() {
    this.$root.$on('chargeShare', async () => {
      // your code goes here
      this.patient_id = this.data_patient.id
      await this.createShareBucket()
      await this.getRecipes()
      await this.getConsultations();
      await this.getAllIdSchemes()
      await this.getAllTreatment()
      await this.getInterConsultations()
      await this.sendParams()
      await this.getDiagnosis()
    })
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
  methods: {
    ...mapActions('share', ['createName', 'shareEmail', 'shareWhatsapp', 'shareCopy']),
    ...mapActions('treatment', ['findTreatmentAppointment', 'getOneTreatmentNoFiles', 'getStockPicking', 'findTreatmentAppointmentCustomer']),
    ...mapActions('patient', ['findPatientAllRecipe', 'findPatientRecipe', 'findPricelistPatient', 'generateQrRecipe', 'findPatientAllConsultations', 'findPatientOneConsultations', "generateQrStudies",'findOneDescConsultationNote','findPatientOne','getFindFullName']),
    ...mapActions('schemes', ['getSchemesPatient', 'getSchemesPatientOne']),
    ...mapActions('studies', ['findStudiesAll']),
    ...mapActions('inter_consultations', ['findPatientAllInterConsultationsDesc',
      'findPatientOneInterConsultationsDesc',
      'getMedicalsInter',
      'createExtraMedical'
    ]),
    async getDiagnosis(){
      const response = await this.findOneDescConsultationNote(this.data_patient.id);
      if (response.consultation){
        this.note_evolution = response.consultation.note_evolution
      }
    },
    async viewConsultation(id) {
      return await this.findPatientOneInterConsultationsDesc(id);


    },

    async copyLink(url) {

      await navigator.clipboard.writeText(url);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Se copio el link correctamente',
          icon: 'EditIcon',
          variant: 'success',
        },
      });

    },
    openModal() {
      this.$bvModal.show('modal-email')
    },
    openModalPhone() {
      this.$bvModal.show('modal-phone')
    },
    async getConsultations() {
      const response = await this.findStudiesAll(this.patient_id);
      this.studiesLab = []
      if (response.consultations.length > 0) {

        for (const a of response.consultations) {
          if (a.ClinicOrderStudy?.ClinicStudiesLines.length > 0 && a.ClinicOrderStudy) {

            this.studiesLab.push({
              id: a.id,
              idStudies: a.ClinicOrderStudy.id,
              createdAt: a.createdAt,
              status: false
            })

          }
        }

      }
    },
    async sendWhatsapp() {
      this.show = true
      this.phone_is = false
      if (this.send.phone) {
        this.phone_is = false
        const response = await this.shareWhatsapp(this.send);
        if (response.status) {
          window.open(`https://api.whatsapp.com/send?phone=521${this.send.phone}&text=Hola buen dia anexo los archivos %0A${response.lines}`)
          this.$bvModal.hide('modal-phone')
          this.send.array_send = []
          this.recipes = []
          this.list_schemes = []
          this.treatments = []
          this.show = false
          await this.getRecipes()
          await this.getAllIdSchemes()
          await this.getAllTreatment()
        } else {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al enviar el whatsapp consulte con su administrador',
              icon: 'warning',
              variant: 'warning',
            },
          });
        }
      } else {
        this.show = false
        this.phone_is = true
      }
    },
    async openModalCopy() {
      this.show = true
      const response = await this.shareCopy(this.send);
      if (response.status) {
        this.lines_copy = response.lines
        this.show = false
        this.$bvModal.show('modal-copy')
      } else {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al generar url consulte con su administrador',
            icon: 'warning',
            variant: 'warning',
          },
        });
      }
    },
    async sendEmail() {
      this.show = true
      this.email_is = false
      if (this.send.email) {
        this.email_is = false
        const response = await this.shareEmail(this.send);
        if (response) {

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Correo se envio correctamente',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
          this.show = false
          this.send.array_send = []
          this.recipes = []
          this.list_schemes = []
          this.treatments = []
          await this.getRecipes()
          await this.getAllIdSchemes()
          await this.getAllTreatment()
          this.$bvModal.hide('modal-email')
        } else {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al enviar el correo consulte con su administrador',
              icon: 'warning',
              variant: 'warning',
            },
          });
        }
      } else {
        this.show = false
        this.email_is = true
      }
    },
    async checkEmail(idx, id, type, verify) {
      if (type === 'recipe') {
        if (verify) {
          const response = await this.findPatientRecipe(id)
          const a = await this.exportPDFRecipeDigital(response)
          this.recipes[idx].status = verify
          const name_file = this.data_patient.name + this.resHoursOdooFormat(response.createdAt)
          this.send.array_send.push({
            pdf: a,
            type: type,
            name: slugify(name_file),
            value: type + id
          })
        } else {
          this.send.array_send.splice(this.send.array_send.findIndex(item => item.value === type + id), 1)
        }
      } else if (type === 'treatment') {
        if (verify) {
          const response = await this.getOneTreatmentNoFiles(id)
          const a = await this.exportPDFTreatment(response)
          this.treatments[idx].status = verify
          const name_file = this.data_patient.name + response.name + this.resHoursOdooFormatNoHour(response.createdAt)
          this.send.array_send.push({
            pdf: a,
            type: type,
            name: slugify(name_file),
            value: type + id
          })

        } else {
          this.send.array_send.splice(this.send.array_send.findIndex(item => item.value === type + id), 1)
        }
      } else if (type === 'studies') {
        if (verify) {
          this.show = true
          const response = await this.findPatientOneConsultations(id);
          this.show = false
          const a = await this.exportPDFStudies(response)
          this.studiesLab[idx].status = verify
          const name_file = this.data_patient.name + response.name + this.resHoursOdooFormatNoHour(response.createdAt)
          this.send.array_send.push({
            pdf: a,
            type: type,
            name: slugify(name_file),
            value: type + id
          })

        } else {
          this.send.array_send.splice(this.send.array_send.findIndex(item => item.value === type + id), 1)
        }
      } else if (type === 'inter') {
        if (verify) {
          this.show = false
          const response = await this.viewConsultation(id);
          this.show = false
          const a = await this.exportInterPrint(response)
          this.interConsults[idx].status = verify
          const name_file = this.data_patient.name + response.id + this.resHoursOdooFormatNoHour(response.createdAt)
          this.send.array_send.push({
            pdf: a,
            type: type,
            name: slugify(name_file),
            value: type + id
          })

        } else {
          this.send.array_send.splice(this.send.array_send.findIndex(item => item.value === type + id), 1)
        }
      } else {
        if (verify) {
          const response = await this.getSchemesPatientOne({id, patient_id: this.patient_id});
          const a = await this.exportPDFScheme(response);
          this.list_schemes[idx].status = verify
          const name_file = this.data_patient.name + response.Chemotherapy.name + this.resHoursOdooFormatNoHour(response.createdAt)
          this.send.array_send.push({
            pdf: a,
            name: slugify(name_file),
            type: type,
            value: type + id
          })
        } else {
          this.send.array_send.splice(this.send.array_send.findIndex(item => item.value === type + id), 1)
        }
      }
    },
    async exportPDFStudies(data) {
      var doc = new jsPDF("p", "pt");
      const img = new Image();
      img.src = logo;
      const pageHeight = doc.internal.pageSize.height;
      doc.addImage(img, "png", 40, 20, 120, 60);
      doc.text(`Estudios de Laboratorio`, 225, 50);
      doc.setFontSize(13);
      doc.text(
          `Fecha: ${data.date_consultation_hours ? formatHour(data.date_consultation_hours) : dateStringMonth()}`,
          400,
          50
      );
      doc.text(`${this.getUser.name ? this.getUser.name : ""}`, 40, 120);
      doc.text(
          `RFC: ${
              this.getUser.vat ? this.getUser.vat : ""
          }`,
          340,
          120
      );
      doc.text(`Especialidad: ${this.getUser.study_field ? this.getUser.study_field : ''}`, 40, 140);
      doc.text(`Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} / ${this.getUser.study_school ? this.getUser.study_school : ''}`, 40, 165);
      doc.text(`Nombre Paciente:`, 40, 190);
      doc.text(`${this.data_patient.name}`, 40, 205);

      if (data.years) {
        doc.text(`Edad:`, 340, 190);
        doc.text(`${data.years ? data.years : ""} Años`, 340, 205);
      } else {
        doc.text(`Edad:`, 340, 190);
      }
      if (this.data_patient.birthday) {
        doc.text(`Fecha Nacimiento:`, 400, 190);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 400, 205);
      } else {
        doc.text(`Fecha Nacimiento:`, 400, 190);
      }
      doc.text(`Laboratorio: ${data.ClinicOrderStudy.name ? data.ClinicOrderStudy.name : ""}`, 40, 235);
      doc.text(`Dirección: ${data.ClinicOrderStudy.address ? data.ClinicOrderStudy.address : ""}`, 40, 255);
      doc.text(`Telefono: ${data.ClinicOrderStudy.phone ? data.ClinicOrderStudy.phone : ""}`, 40, 275);



      let textDiagnosis = null;
      if (data.ClinicDiagnostics.length > 0){
        const dataTextDiagnosis = data.ClinicDiagnostics
            .map(item => item.product)
            .filter(productName => productName !== '')
            .join(',');

        textDiagnosis = doc.splitTextToSize(dataTextDiagnosis ? dataTextDiagnosis : "", 400)
      }

      doc.text(`Diagnostico:`, 40, 295);
      doc.text(textDiagnosis, 40, 310);
      doc.text(`Observaciones:`, 40, 370);
      const observationsText = data.ClinicOrderStudy.observations  ? data.ClinicOrderStudy.observations  : "";
      const strArrBack = doc.splitTextToSize(observationsText, 500);
      let currentHeight = 390;


      strArrBack.forEach((line, index) => {
        if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
          doc.text(`Observaciones:`, 40, 40);
          currentHeight = 70;
        }
        doc.text(line, 40, currentHeight);
        currentHeight += 15;
      });

      if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
        currentHeight = 40;
      }


      doc.autoTable({
        columns: [
          {header: "Estudio", dataKey: "product"},
          {header: "Indicaciones", dataKey: "indications"},
        ],
        body: data.ClinicOrderStudy.ClinicStudiesLines,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        startY: currentHeight,
      });
      currentHeight = doc.lastAutoTable.finalY + 20;

      if (this.checkAndAddNewPagePt((currentHeight + 120), pageHeight, doc)) {
        currentHeight = 40;
      }
      const send = {
        date_consultation: data.date_consultation ? data.date_consultation : "---",
        name_doctor: data.name_doctor ? data.name_doctor : "---",
        permit_no: data.permit_no ? data.permit_no : "---",
        vat: data.vat ? data.vat : "---",
      }
      const responseQr = await this.generateQrStudies(send);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 240, currentHeight, 120, 120);
      const addFooters = async (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        let responsePatient;
        if(this.getUser.address_id){
          responsePatient = await this.findPatientOne(this.getUser.address_id);
        }


        for(let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const footerName = `${this.getUser.name || ''} ${this.getUser.study_field? this.getUser.study_field : ''} ` ;
          const footerText = `Cedula: ${this.getUser.permit_no ? this.getUser.permit_no :''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} ${this.getUser.study_school ? this.getUser.study_school : ''}`;
          const footerAddress = `${responsePatient ? responsePatient.contact_address_complete :''}`;
          const footerPosition = doc.internal.pageSize.getHeight();
          doc.text(footerAddress, 40, footerPosition - 25);
          doc.text(footerText, 40, footerPosition - 40);
          doc.text(footerName, 40, footerPosition-55);
        }
      };

      await addFooters(doc);
      return doc.output('datauristring')
    },
    async sendParams() {
      this.send.id = this.data_patient.id
      this.send.name = this.data_patient.name
      this.send.email = this.data_patient.email
      this.send.bucket = this.data_patient.bucket_path
      this.send.phone = this.data_patient.mobile
      const response_pricelist = await this.findPricelistPatient(this.data_patient.id)
      this.pricelist_patient = response_pricelist.name
    },
    async createShareBucket() {
      await this.createName(this.patient_id)
    },
    async getRecipes() {
      this.recipes = []
      const response = await this.findPatientAllRecipe(this.patient_id);
      for (const a of response) {
        this.recipes.push({
          id: a.id,
          createdAt: a.createdAt,
          status: false

        })
      }
    },
    async exportPDFRecipeDigital(data) {
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4",
      };
      var doc = new jsPDF(options);
      const a = this.data_patient.name;
      const b = data.date_consultation;

      doc.setFontSize(8);
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 1, 1, 4, 2);
      doc.setFontSize(12);
      doc.text(`Receta Médica Digital`, 9, 1.5);
      doc.setFontSize(10);
      doc.text(
          `Fecha de consulta: ${formatHour(data.date_consultation_hours)}`,
          6,
          2.5
      );
      doc.text(
          `Fecha de Vigencia: ${sumMonthsHours(data.date_consultation_hours, 1)}`,
          12,
          2.5
      );
      doc.text(`Folio: ${data.folio ? data.folio:""}`, 6, 3.3);
      doc.text(`${this.getUser.name ? data.name:""}`, 1, 4.3);
      doc.text(
          `RFC: ${
              this.getUser.vat ? this.getUser.vat : ""
          }`,
          8,
          4.3
      );
      doc.text(`Especialidad: ${this.getUser.study_field? this.getUser.study_field : ''}`, 1, 5);
      doc.text(`Cedula: ${this.getUser.permit_no ? this.getUser.permit_no :''} / Cedula de especialidad: ${this.getUser.visa_no ? data.visa_no : ''} ${this.getUser.study_school ? this.getUser.study_school : ''}`, 1, 5.8);
      doc.text(`Nombre Paciente:`, 1, 6.5);
      doc.text(`${this.data_patient.name}`, 1, 7);

      if (data.years) {
        doc.text(`Edad:`, 8, 6.5);
        doc.text(`${data.years ? data.years : ""} Años`, 8, 7);
      } else {
        doc.text(`Edad:`, 8, 6.5);
      }
      if (this.data_patient.birthday) {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 12, 7);
      } else {
        doc.text(`Fecha de nacimiento:`, 12, 6.5);
      }

      if (data.ClinicDiagnostics.length > 0){
        let dataDiagnosis = []
        for (const a of data.ClinicDiagnostics){
          if (a.product){
            dataDiagnosis.push(a.product)
          }
        }
        if (dataDiagnosis.length > 0){
          let result = dataDiagnosis.map(item => ` - ${item}`).join(',');
          const strArr = doc.splitTextToSize(result ? result : "", 20)
          doc.text('Diagnosticos:', 1, 7.8);
          doc.text(strArr, 1, 8.3);
        }

      }


      let finalY = 9.5;

      doc.autoTable({
        columns: [
          { header: "Medicamento", dataKey: "product" },
          { header: "Indicaciones", dataKey: "indications" },
        ],
        startY: 9.5,
        headStyles: { fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
        bodyStyles: { fontSize: 8 },
        body: data.ClinicRecipes,
        didDrawPage: function(data) {
          finalY = data.cursor.y;
        },
      });

      if (finalY >= 26.7) {
        doc.addPage();
        finalY = 2;
      }


      if (this.data_patient.ResPartnerAppointment) {
        if (this.data_patient.ResPartnerAppointment.HrEmployee){
          if (this.data_patient.ResPartnerAppointment.HrEmployee.signature){

            finalY += 2;
          }
        }
      }

      const send = {
        folio: data.folio,
        date_consultation: data.date_consultation,
        name_doctor: data.name_doctor,
        permit_no: data.permit_no,
        vat: data.vat,
      }
      const responseQr = await this.generateQrRecipe(send);
      doc.text(this.getUser.name || '', 8, finalY + 2);
      doc.text("Beethoven 287, Juan Manuel, 44680 Guadalajara, Jal.", 6.5, finalY + 9);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 8, finalY + 3.5, 4, 4);

      const addFooters = async (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        let responsePatient;
        if(this.getUser.address_id){
          responsePatient = await this.findPatientOne(this.getUser.address_id);
        }


        for(let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const footerName = `${this.getUser.name || ''} ${this.getUser.study_field? this.getUser.study_field : ''} ` ;
          const footerText = `Cedula: ${this.getUser.permit_no ? this.getUser.permit_no :''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} ${this.getUser.study_school ? this.getUser.study_school : ''}`;
          const footerAddress = `${responsePatient ? responsePatient.contact_address_complete :''}`;
          const footerPosition = doc.internal.pageSize.getHeight();
          doc.text(footerAddress, 1, footerPosition - 0.5);
          doc.text(footerText, 1, footerPosition - 1);
          doc.text(footerName, 1, footerPosition-1.5);
        }
      };


      await addFooters(doc);
      return doc.output('datauristring')
    },
    async exportPDFRecipe(data) {
      const options = {
        orientation: "p",
        unit: "cm",
        format: "a4"
      }
      var doc = new jsPDF(options);

      doc.setFontSize(10);

      doc.text(`${this.data_patient.name}`, 2, 1);
      doc.text(`${data.ResPartnerAppointment.name}`, 2, 2);
      doc.text(`${this.dateStringEs(data.date_consultation)}`, 2, 3);


      doc.autoTable({
        columns: [
          {header: 'Medicamento', dataKey: 'product'},
          {header: 'Indicaciones', dataKey: 'indications'},

        ],
        startY: 4,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: data.ClinicRecipes,
      })
      return doc.output('datauristring')
    },
    async exportPDFTreatment(data) {
      const d1 = this.resHoursOdoo(data.app_dt_start)
      const d2 = this.resHoursOdoo(data.app_dt_stop)
      const date = d1.split(" ")
      const date2 = d2.split(" ")
      var doc = new jsPDF('p', 'pt', 'a4');
      const count_lines = data.ClinicTreatment.ClinicTreatmentLines.length
      const response_pi = await this.getStockPicking(data.id)
      let picking;
      if (response_pi) {
        const names = response_pi.map(item => item.name).join(', ');
        picking = names
      } else {
        picking = null
      }
      doc.text(`Tratamiento`, 40, 40);
      if (picking) {
        doc.text(`Salida: ${picking}`, 350, 40);
      } else {

      }
      doc.text(`Paciente: ${this.data_patient.name}`, 40, 70);
      doc.text(`Tipo de paciente: ${this.pricelist_patient}`, 40, 100);
      doc.text(`Fecha: ${date[0]}`, 40, 130);
      doc.text(`Horario: ${date[1]} - ${date2[1]}`, 200, 130);
      let lines = []
      for (const z of data.ClinicTreatment.ClinicTreatmentLines) {
        lines.push({
          name: z.ProductTemplate.name,
          qty: z.qty
        })
      }
      doc.autoTable({
        columns: [
          {header: 'Medicamento', dataKey: 'name'},
          {header: 'Lote', dataKey: ''},
          {header: 'Caducidad', dataKey: 'a'},
          {header: 'Cantidad', dataKey: 'qty'},
        ],
        body: lines,
        margin: {top: 150},
      })
      let finalY = doc.autoTable.previous.finalY
      if (finalY >= 600) {
        doc.addPage();
        doc.text(`Extras:____________________________________________________`, 40, 60);
        doc.text(`__________________________________________________________`, 40, 100);
        doc.text(`__________________________________________________________`, 40, 140);
        doc.text(`Nombre:____________________`, 40, 220);
        doc.text(`Firma:______________________`, 40, 260);
        doc.text(`Nombre:____________________`, 305, 220);
        doc.text(`Firma:______________________`, 305, 260);
      } else {
        doc.text(`Extras:____________________________________________________`, 40, finalY + 40);
        doc.text(`__________________________________________________________`, 40, finalY + 80);
        doc.text(`__________________________________________________________`, 40, finalY + 120);
        doc.text(`Nombre:____________________`, 40, finalY + 220);
        doc.text(`Firma:______________________`, 40, finalY + 260);
        doc.text(`Nombre:____________________`, 305, finalY + 220);
        doc.text(`Firma:______________________`, 305, finalY + 260);
      }
      return doc.output('datauristring')
    },

    async exportPDFScheme(data) {

      const options = {
        orientation: "l",
        unit: "cm",
        format: "a4"
      }
      var doc = new jsPDF(options);
      doc.setFontSize(12);
      const img = new Image();
      img.src = logo;
      doc.addImage(img, "png", 1, 1, 4, 2);
      doc.text(`Paciente: ${this.data_patient.name ? this.data_patient.name : ''}, Medico: ${data.HrEmployee.name ? data.HrEmployee.name : ''}, Especialidad: ${data.HrEmployee.study_field ? data.HrEmployee.study_field : ''}`, 6, 2);
      doc.text(`RFC: ${data.HrEmployee.ResPartnerAuth?.vat  ? data.HrEmployee.ResPartnerAuth.vat : ""}, Cedula: ${data.HrEmployee.permit_no ? data.HrEmployee.permit_no : ''} / Cedula de especialidad: ${data.HrEmployee.visa_no ? data.HrEmployee.visa_no : ''} / ${data.HrEmployee.study_school ? data.HrEmployee.study_school : ''}`, 6, 3);
      doc.text(`Edad: ${data.age}.`, 2, 4);
      doc.text(`Peso: ${data.weight} Kg.`, 4.5, 4);
      doc.text(`Estatura: ${data.size} Mts.`, 7.5, 4);
      doc.text(`Superficie Corporal: ${data.body_surface}`, 11.5, 4);
      doc.text(`Sexo: ${data.gender === 'M' ? 'Masculino' : 'Femenino'}`, 16.5, 4);
      doc.text(`Dia de inicio: ${data.date_initial}`, 2, 5);
      doc.text(`Dia Final: ${data.date_end}`, 7, 5);
      doc.text(`Periocidad: ${data.cycle}`, 12, 5);
      doc.text(`Total de ciclos: ${data.total_cycle}`, 15, 5);
      doc.text(`Diagnostico: ${data.diagnosis}`, 2, 6);
      doc.text(`Tratamiento: ${data.Chemotherapy.name}`, 2, 7);
      doc.text(`Comentarios:`, 2, 8);
      const strArrAll = doc.splitTextToSize(data.comments ? data.comments : '', 23)
      const sumOtherAll= 8 + 1;
      doc.text(strArrAll, 2, sumOtherAll);
      const firstParaHeight5 = doc.getTextDimensions(strArrAll, {fontSize: doc.internal.getFontSize()}).h;
      const sumAll = sumOtherAll + firstParaHeight5;
      doc.text(`Notas de evolución :`,  2, sumAll+1);
      const strArrAllDesc = doc.splitTextToSize(this.note_evolution ? this.note_evolution : '---', 23)
      doc.text(strArrAllDesc, 2, sumAll+2);
      const firstParaHeight6 = doc.getTextDimensions(strArrAllDesc, {fontSize: doc.internal.getFontSize()}).h;
      const sumAl1 = sumAll + firstParaHeight6
      let lines = []
      doc.setFontSize(10);
      for (const f of data.ChemotherapyLinesAssigns) {
        if (f.ProductTemplate) {
          lines.push({
            order: f.order,
            total_dose: f.total_dose,
            auc: f.auc,
            creatinine: f.creatinine,
            distribution_volume: f.distribution_volume,
            dose: f.dose,
            dose_carboplatino: f.dose_carboplatino,
            filtration_rate: f.filtration_rate,
            infusion_time: f.infusion_time,
            treatment_days: f.treatment_days,
            premedication: f.premedication ? 'si' : 'no',
            product: f.ProductTemplate.name,
            unit_dose: f.ChemotherapyUnitDose.name,
            via_admin: f.ChemotherapyViaAdmin.name,
            unit_time: f.ChemotherapyUnitTime.name,
          })
        }
      }

      doc.autoTable({
        columns: [
          {header: 'Orden', dataKey: 'order'},
          {header: 'Preme...', dataKey: 'premedication'},

          {header: 'Medi...', dataKey: 'product'},
          {header: 'Dosi...', dataKey: 'dose'},
          {header: 'U. Dos...', dataKey: 'unit_dose'},
          {header: 'Total', dataKey: 'total_dose'},
          {header: 'Via. Admin...', dataKey: 'via_admin'},
          {header: 'VD', dataKey: 'distribution_volume'},
          {header: 'Tiem. infu...', dataKey: 'infusion_time'},
          {header: 'Unidad. I...', dataKey: 'unit_time'},
          {header: 'Dias Tx...', dataKey: 'treatment_days'},
          {header: 'Creatinina...', dataKey: 'creatinine'},
          {header: 'Auc', dataKey: 'auc'},
          {header: 'Tasa Fil...', dataKey: 'filtration_rate'},

          {header: 'Dose Carbo...', dataKey: 'dose_carboplatino'},

        ],
        startY: sumAl1+3,
        headStyles: {fillColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8},
        bodyStyles: {fontSize: 8},
        body: lines,
      })
      const addFooters = async () => {
        const pageCount = doc.internal.getNumberOfPages();
        let responsePatient;

        if (data.HrEmployee.address_id) {
          responsePatient = await this.findPatientOne(data.HrEmployee.address_id);
        }

        this.show = true
        const responseDataSignature = await this.getFindFullName(this.getUser.id);
        this.show = false
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);

          const footerName = `${this.getUser.name || ''} ${this.getUser.study_field ? this.getUser.study_field : ''} `;
          const footerText = `Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} ${this.getUser.study_school ?this.getUser.study_school : ''} ${responsePatient ? responsePatient.contact_address_complete : ''}`;
          const footerPosition = doc.internal.pageSize.getHeight() - 1; // Ajustar según el margen inferior deseado

          doc.text(footerText, 1.5, footerPosition + 0.5);
          doc.text(footerName, 1.5, footerPosition);

          if (responseDataSignature && responseDataSignature.signatureFile) {
            const img2 = new Image();
            img2.src = `data:image/png;base64,${responseDataSignature.signatureFile}`;
            doc.addImage(img2, "PNG", 25, footerPosition - 1.2, 1.7, 1.7);
          }
        }
      };

      await addFooters(doc);
      return doc.output('datauristring')
    },
    async getAllIdSchemes() {
      this.list_schemes = []
      const response = await this.getSchemesPatient(this.data_patient.id);
      for (const a of response) {
        this.list_schemes.push({
          id: a.id,
          name: a.Chemotherapy.name,
          createdAt: a.createdAt,
          status: false
        })
      }
    },
    async getInterConsultations() {
      this.interConsults = []
      const responseDesc = await this.findPatientAllInterConsultationsDesc(this.data_patient.id);
      if (responseDesc.length > 0) {
        for (const a of responseDesc) {
          this.interConsults.push({
            id: a.id,
            createdAt: a.createdAt,
            status: false
          })
        }
      }
    },
    async getAllTreatment() {
      this.treatments = []
      const response = await this.findTreatmentAppointmentCustomer(this.data_patient.id);
      for (const a of response) {
        this.treatments.push({
          id: a.id,
          name: a.AppointmentTreat.name,
          createdAt: a.createdAt,
          status: false
        })
      }
    },
    async exportInterPrint(data) {
      var doc = new jsPDF("p", "pt");
      const img = new Image();
      const pageHeight = doc.internal.pageSize.height;
      img.src = logo;
      doc.addImage(img, "png", 40, 20, 120, 60);
      doc.text(`Interconsulta`, 225, 50);
      doc.setFontSize(13);
      doc.text(
          `Fecha: ${ data.date_consultation_hours ? formatHour(data.date_consultation_hours): dateTodayFormat()}`,
          400,
          50
      );
      doc.setFontSize(18);
      doc.text(`Información de mi doctor:`, 40, 110);
      doc.setFontSize(13);
      doc.text(`${this.getUser.name ? this.getUser.name : ""}`, 40, 130);
      doc.text(
          `RFC: ${
              this.getUser.vat ? this.getUser.vat : ""
          }`,
          340,
          130
      );
      doc.text(`Especialidad: ${this.getUser.study_field ? this.getUser.study_field : ''}`, 40, 150);
      doc.text(`Cedula: ${this.getUser.permit_no ? this.getUser.permit_no : ''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} / ${this.getUser.study_school ? this.getUser.study_school : ''}`, 40, 175);
      doc.text(`Nombre Paciente:`, 40, 200);
      doc.text(`${this.data_patient.name}`, 40, 215);

      if (data.years) {
        doc.text(`Edad:`, 340, 200);
        doc.text(`${data.years ? data.years : ""} Años`, 340, 215);
      } else {
        doc.text(`Edad:`, 340, 200);
      }
      if (this.data_patient.birthday) {
        doc.text(`Fecha Nacimiento:`, 430, 200);
        doc.text(`${this.data_patient.birthday ? this.dateStringMxFormat(this.data_patient.birthday) : ""}`, 430, 215);
      } else {
        doc.text(`Fecha Nacimiento:`, 430, 200);
      }
      doc.setFontSize(18);
      doc.text(`Datos de medico relacionado:`, 40, 250);
      doc.setFontSize(13);
      doc.text(`Nombre: ${data.name ? data.name : ""}`, 40, 280);
      doc.text(`Especialidad del Médico: ${data.specialty ? data.specialty : ""}`, 40, 310);
      doc.text(`Teléfono: ${data.phone ? data.phone : ""}`, 40, 340);
      doc.text(`Cedula: ${data.identification_medical ? data.identification_medical : ""}`, 40, 370);
      const dataAddress = `Dirección: ${data.address ? data.address : ""}`
      var addressData = doc.splitTextToSize(dataAddress, 500);
      doc.text(addressData, 40, 400);
      let currentHeight = 460;
      doc.text(`Motivo:`, 40, currentHeight);
      if (data.reason) {
        var strArr = doc.splitTextToSize(data.reason, 500);
        currentHeight += 20;
        strArr.forEach((line, index) => {
          if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
            doc.text(`Motivo:`, 40, 40);
            currentHeight = 70;
          }
          doc.text(line, 40, currentHeight);
          currentHeight += 15;
        });
      }
      if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
        currentHeight = 40;
      }
      doc.text(`Diagnostico de la ultima consulta:`, 40, currentHeight+ 20);

      if (data.last_consult) {
        var splitText = doc.splitTextToSize(data.last_consult, 540);
        currentHeight += 20;
        splitText.forEach((line, index) => {
          if (this.checkAndAddNewPagePt(currentHeight, pageHeight, doc)) {
            doc.text(`Diagnostico de la ultima consulta:`, 40, 40);
            currentHeight = 70;
          }
          doc.text(line, 40, currentHeight + 14);
          currentHeight += 15;
        });
      }

      if (this.checkAndAddNewPagePt((currentHeight + 120), pageHeight, doc)) {
        currentHeight = 40;
      }


      const send = {
        date_consultation: data.date_consultation_hours ? data.date_consultation_hours : "---",
        name_doctor: data.name_doctor ? data.name_doctor : "---",
        permit_no: data.permit_no ? data.permit_no : "---",
        vat: data.vat ? data.vat : "---",
      };

      const responseQr = await this.generateQrStudies(send);
      const img3 = new Image();
      img3.src = responseQr.code;
      doc.addImage(img3, "png", 240, currentHeight, 120, 120);
      const addFooters = async (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        let responsePatient;
        if(this.getUser.address_id){
          responsePatient = await this.findPatientOne(this.getUser.address_id);
        }


        for(let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const footerName = `${this.getUser.name || ''} Especialidad: ${this.getUser.study_field? this.getUser.study_field : ''} ` ;
          const footerText = `Cedula: ${this.getUser.permit_no ? this.getUser.permit_no :''} / Cedula de especialidad: ${this.getUser.visa_no ? this.getUser.visa_no : ''} ${this.getUser.study_school ? this.getUser.study_school : ''}`;
          const footerAddress = `${responsePatient ? responsePatient.contact_address_complete :''}`;
          const footerPosition = doc.internal.pageSize.getHeight();
          doc.text(footerAddress, 40, footerPosition - 25);
          doc.text(footerText, 40, footerPosition - 40);
          doc.text(footerName, 40, footerPosition-55);
        }
      };

      await addFooters(doc);
      return doc.output('datauristring')
    },
  }
}
</script>

<style scoped>
.card-recipe {
  -webkit-box-shadow: 1px 1px 9px -1px #000000;
  box-shadow: 1px 1px 9px -1px #000000;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
}

</style>
